<template>
  <div class="taskInfo">
    <div class="taskInfo-layout">
      <van-cell-group :border="taskBorder">
        <van-cell title="结算项目" :value="formData.name" />
        <van-cell title="结算企业" :value="formData.merchantName" />
        <van-cell title="结算时间" :value="formData.createTime"/>
        <van-cell title="结算金额" :value="formData.salaryRange" />
        <van-cell title="备注" :value="remark" />
      </van-cell-group>
      <van-form @submit="onSubmit">
        <van-field
          v-model="formData.describe"
          rows="3"
          autosize
          label="结算说明"
          type="textarea"
          readonly
          placeholder="结算说明"
        />
        <van-field name="uploader" label="附件">
          <template #input>
            <van-uploader
              :max-size="10 * 1024 * 1024"
              v-model="fj"
              accept="image/*"
              :after-read="afterRead"
              @delete="delImg"
              :max-count="8"/>
          </template>
        </van-field>

        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit" :loading="upLoading" :disabled="upLoading" loading-text="加载中...">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>

</template>
<script>
import requestHTAPI from '@/axios/HantangTax'
import Exif from 'exif-js'
export default {
  name: 'settlementInfo',
  data () {
    return {
      taskBorder: false,
      fj: [],
      realFj: [],
      formData: {

      },
      files: {
        name: '',
        type: ''
      },
      id: '',
      remark: '',
      upLoading: false
    }
  },
  mounted () {
    this.onLoad()
  },
  methods: {
    onLoad () {
      const that = this
      this.id = this.$route.query.id
      // this.describe = this.$route.query.describe
      requestHTAPI.getOneDeliveryFile({
        signUpId: this.id
      }).then(res => {
        if (res.data.code === '0000') {
          const files = res.data.data.files
          const detail = res.data.data.detail
          this.remark = res.data.data.remark
          if (detail) {
            this.formData = res.data.data.detail
          }
          if (files) {
            this.realFj = files.map(item => {
              this.fj.push({
                url: item.filePath,
                isImage: true
              })
              return item.filePath
            })
          }
        } else {
          this.$notify({
            type: 'danger',
            message: res.data.message
          })
        }
      })
    },
    // 上传处理
    afterRead (file, detail) {
      this.upLoading = true
      const formData = new FormData()
      formData.append('file', file.file)
      requestHTAPI.picZip(formData).then(res => {
        if (res.data.code == '0000') {
          this.realFj.push(res.data.data.filePath)
          // this.fj.push({
          //   url:res.data.data.url
          // })
        } else {
          this.$notify({
            type: 'danger',
            message: res.data.message
          })
        }
        this.upLoading = false
      })
      // this.files.name = file.file.name // 获取文件名
      // this.files.type = file.file.type // 获取类型
      // this.imgPreview(this.fj[detail.index].file, detail.index)
    },
    delImg (file, detail) {
      this.realFj.splice(detail.index, 1)
    },
    onSubmit () {
      let arr = []
      arr = this.realFj.map(function (res) {
        return res
      })
      // const formData = new FormData()
      // const data = this.fj
      // for (let i = 0; i < data.length; i++) {
      //   formData.append('files', data[i].file)
      // }
      // formData.append('files', this.realFj)
      // formData.append('remark', this.formData.message)
      // formData.append('signUpId', this.id)
      requestHTAPI.addDeliveryFile({
        files: arr,
        signUpId: this.id
        // remark:this.formData.message,
      }).then(res => {
        if (res.data.code === '0000') {
          // this.formData.message = ''
          // this.fj = []
          this.$notify({
            type: 'success',
            message: '提交成功'
          })
        } else {
          this.$notify({
            type: 'danger',
            message: res.data.message
          })
        }
      })
    },
    // 转base图片
    imgPreview (file, index) {
      const self = this
      let Orientation
      Exif.getData(file, function () {
        Orientation = Exif.getTag(this, 'Orientation')
      })
      if (!file || !window.FileReader) return
      if (/^image/.test(file.type)) {
        // 创建一个reader
        const reader = new FileReader()
        // 将图片2将转成 base64 格式
        reader.readAsDataURL(file)
        // 读取成功后的回调
        reader.onloadend = function () {
          const result = this.result
          const img = new Image()
          img.src = result
          // 判断图片是否大于500K,是就直接上传，反之压缩图片
          if (this.result.length <= 500 * 1024) {
            // 上传图片c
            self.postImg(this.result, index)
          } else {
            img.onload = function () {
              const data = self.compress(img, Orientation)
              // 上传图片
              self.postImg(data, index)
            }
          }
        }
      }
    },
    compress (img, Orientation) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const tCanvas = document.createElement('canvas')
      const tctx = tCanvas.getContext('2d')
      let width = img.width
      let height = img.height
      // 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio
      if ((ratio = (width * height) / 4000000) > 1) {
        // console.log("大于400万像素");
        ratio = Math.sqrt(ratio)
        width /= ratio
        height /= ratio
      } else {
        ratio = 1
      }
      canvas.width = width
      canvas.height = height
      //    铺底色
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      // 如果图片像素大于100万则使用瓦片绘制
      let count
      if ((count = (width * height) / 1000000) > 1) {
        // console.log("超过100W像素");
        count = ~~(Math.sqrt(count) + 1) // 计算要分成多少块瓦片
        //      计算每块瓦片的宽和高
        const nw = ~~(width / count)
        const nh = ~~(height / count)
        tCanvas.width = nw
        tCanvas.height = nh
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh)
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
          }
        }
      } else {
        ctx.drawImage(img, 0, 0, width, height)
      }
      // 修复ios上传图片的时候 被旋转的问题
      if (Orientation != '' && Orientation != 1) {
        switch (Orientation) {
          case 6: // 需要顺时针（向左）90度旋转
            this.rotateImg(img, 'left', canvas)
            break
          case 8: // 需要逆时针（向右）90度旋转
            this.rotateImg(img, 'right', canvas)
            break
          case 3: // 需要180度旋转
            this.rotateImg(img, 'right', canvas) // 转两次
            this.rotateImg(img, 'right', canvas)
            break
        }
      }
      // 进行最小压缩
      const ndata = canvas.toDataURL('image/jpeg', 0.1)
      tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0
      return ndata
    },
    // 使用canvas压缩图片
    // 旋转图片
    rotateImg (img, direction, canvas) {
      // 最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0
      const max_step = 3
      if (img == null) return
      // img的高度和宽度不能在img元素隐藏后获取，否则会出错
      const height = img.height
      const width = img.width
      let step = 2
      if (step == null) {
        step = min_step
      }
      if (direction == 'right') {
        step++
        // 旋转到原位置，即超过最大值
        step > max_step && (step = min_step)
      } else {
        step--
        step < min_step && (step = max_step)
      }
      // 旋转角度以弧度值为参数
      const degree = (step * 90 * Math.PI) / 180
      const ctx = canvas.getContext('2d')
      switch (step) {
        case 0:
          canvas.width = width
          canvas.height = height
          ctx.drawImage(img, 0, 0)
          break
        case 1:
          canvas.width = height
          canvas.height = width
          ctx.rotate(degree)
          ctx.drawImage(img, 0, -height)
          break
        case 2:
          canvas.width = width
          canvas.height = height
          ctx.rotate(degree)
          ctx.drawImage(img, -width, -height)
          break
        case 3:
          canvas.width = height
          canvas.height = width
          ctx.rotate(degree)
          ctx.drawImage(img, -width, 0)
          break
      }
    },
    // 将base64转换为文件
    dataURLtoFile (dataurl, filename) {
      filename = filename ? filename + '.png' : this.files.name
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      console.log(this.files)
      return new File([u8arr], filename, {
        type: mime || this.files.type
      })
    },
    postImg (base64, index) {
      const file = this.dataURLtoFile(base64)
      console.log('file', file)
      this.fj[index].file = file
    },
    urlToBase64 (url) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = function () {
          const canvas = document.createElement('canvas')
          canvas.width = this.naturalWidth
          canvas.height = this.naturalHeight
          // 将图片插入画布并开始绘制
          canvas.getContext('2d').drawImage(image, 0, 0)
          // result
          const result = canvas.toDataURL('image/png')
          resolve(result)
        }
        // CORS 策略，会存在跨域问题https://stackoverflow.com/questions/20424279/canvas-todataurl-securityerror
        image.setAttribute('crossOrigin', 'Anonymous')
        image.src = url
        // 图片加载失败的错误处理
        image.onerror = () => {
          reject(new Error('urlToBase64 error'))
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
  .taskInfo {
    /*overflow: hidden;*/
    /*height: 100%;*/
    background: #eee;
  }

  .taskInfo-layout {
    text-align: left;
    padding-top: 40px;
    background: #fff;
    padding-bottom: 150px;
    .van-cell-group {
      .van-cell {
        .van-cell__title {
          flex: 1 !important;
          -webkit-flex: 1 !important;
          justify-content: flex-start !important;
          -webkit-justify-content: flex-start !important;
        }
        .van-cell__value {
          flex: 3 !important;
          -webkit-flex: 3 !important;
          justify-content: flex-end !important;
          -webkit-justify-content: flex-end !important;
        }
      }
      .van-cell:nth-child(5) {
        .van-cell__value {
          text-align: left !important;
        }
      }
    }
    .van-form {
      .van-cell.van-field {
        /deep/ .van-field__label {
          margin-right: 0 !important;
        }
      }
    }
  }

  .fixBtn {
    position: fixed;
    left: 0;
    bottom: 0;
  }

</style>
